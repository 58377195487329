import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import HomepageLayout from "../components/homepage-layout"
import BackgroundImage from "../components/utilities/background-image"
import HomepageMenu from '../components/homepage-menu'
import SEO from "../components/utilities/seo"

import '../styles/pages/index.css'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "artwork/Breakwater.jpeg" }) {
        ...galleryImage
      }
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  
  return <HomepageLayout title={ data.site.siteMetadata.title }>
    <div className='site-background'>
      <BackgroundImage src={ data.background } />
      <div className='page-wrapper'>
        <SEO title="Home"/>
        <HomepageMenu/>
      </div>
    </div>
  </HomepageLayout>
}

export default IndexPage;
