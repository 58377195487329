import React from "react";
import PropTypes from "prop-types";
// import { useStaticQuery, graphql } from "gatsby";

import "../styles/layout.css";

const HomepageLayout = ( { title, children }) => {
//   const data = useStaticQuery(graphql`
//   query SiteTitleQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `)
  return (
    <>
      <main title={title} 
        style={{
          textAlign: `center`,
        }}
      >{children}</main>
      <footer>
        © {new Date().getFullYear()}
      </footer>
    </>
  )
}

HomepageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default HomepageLayout;
