import React, { useRef, useEffect } from "react"
import HeaderLink from './header/header-link'

import '../styles/components/homepage-menu.css'

import leafSwirl from '../images/leaf-swirl.svg';

const HomepageMenu = () => {
  const menuRef = useRef(null);

  useEffect(() => {
    if (menuRef.current) menuRef.current.classList.add('enter--active');
  }, []);

  return (<>
    <div id='menu' ref={menuRef}>
      <img id='menu-swirl' className='icon' alt='swirl with leaves logo' src={leafSwirl} />
      <HeaderLink {...{text:'Gallery', link:'gallery'}}/>
      {/* <HeaderLink {...{text:'Blog', link:'blog'}}/> */}
      <HeaderLink {...{text:'Publications', link:'publications'}}/>
      <HeaderLink {...{text:'Contact', link:'contact'}}/>
    </div>
  </>)
}

export default HomepageMenu;
